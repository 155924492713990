import React from "react"
import { graphql } from "gatsby"
import statWinner from "../../js/statWinner"
//import formatNumComma from "../../js/formatNumComma"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import DetailedStatBars from "../../components/DetailedStats/DetailedStatBars"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import CopyBlock from "../../components/Copy/CopyBlock"
import InfoBar from '../../components/Utilities/InfoBar'

class Shots extends React.Component {

  render() {

    const pageTitle = "xG (Expected Goals)"

    const mdata = this.props.data.allSheetMessiAllTimeStats.edges
    const rdata = this.props.data.allSheetRonaldoAllTimeStats.edges

    const mxg = Math.abs(mdata.filter(d => d.node.competition === "League Since 14").map(d => d.node.xgc))
    const rxg = Math.abs(rdata.filter(d => d.node.competition === "League Since 14").map(d => d.node.xgc))

    const subStat = (x, comp) => {
      return x === "m" ? (
        parseFloat(mdata.filter(d => d.node.competition === comp).map(d => (d.node.goals - d.node.xg))[0]).toFixed(1)
      ) : (
        parseFloat(rdata.filter(d => d.node.competition === comp).map(d => (d.node.goals - d.node.xg))[0]).toFixed(1)
      )
    }

    const comps = [
      {
        "comp": "League (Since 14/15)",
        "mSubStat": subStat('m', 'League Since 14'),
        "rSubStat": subStat('r', 'League Since 14'),
        "btnText": "Season Breakdown"
      }
    ]

    return (

      <Layout>
        <SEO
          title={`xG (Expected Goals) - Messi vs Ronaldo xG Stats`}
          description={`xG is simply the number of goals a player should score based on the quality of their chances - but how do Messi and Ronaldo perform against the expected goals model?`}
          canonicalPath={`/detailed-stats/xg-expected-goals/`}
        />

        <h1>xG (Expected Goals) <span className="sr-only">Messi vs Ronaldo xG Stats</span></h1>

        <div className="flex items-center justify-center mt-8 lg:mt-10 xl:mt-12 max-w-xs mb-2 xl:mb-4 mx-auto px-4">
          <InfoBar classes="w-full justify-center" msg="Non-European league stats not included in totals" />
        </div>

        <div className={`max-w-lg mx-auto flex flex-wrap justify-center`}>

          {comps.map(d => (
            <DetailedStatBars
              key={d.comp}
              data={this.props.data}
              competition={d.comp}
              title={`League xG 14/15 - 22/23`}
              playerTitleHidden={`${d.comp} xG 14/15 - 22/23`}
              statType="xG (Expected Goals)"
              btnText=''
              statView={['Totals', 'Actual Goals vs xG']}
              mSubStat={`Scored ${d.mSubStat.toString() !== "NaN" ? d.mSubStat : '-'} more goals than he "should" have`}
              rSubStat={`Scored ${d.rSubStat.toString() !== "NaN" ? d.rSubStat : '-'} more goals than he "should" have`}
              subStatW={statWinner(d.mSubStat.toString() !== "NaN" ? d.mSubStat : '-', d.rSubStat.toString() !== "NaN" ? d.rSubStat : '-')}
              since="14/15"
            />
          ))}

        </div>

        <CopyBlock>
          <h2>Messi and Ronaldo xG (Expected Goals) Stats</h2>

          <InfoBar /><br />
          <InfoBar msg={`This site uses the xG model from understat.com`} />

          <h3>What does xG or Expected Goals mean?</h3>

          <p>Put simply, xG (which stands for "Expected Goals"), is the number of goals your average striker <em>should</em> score based on the quality of their chances.</p>

          <p>Each shot is assigned an xG value between 0 and 1, with a lower xG value indicating a more difficult chance. A penalty for example has an xG value of 0.74, meaning it should be scored 74% of the time by the average player.</p>

          <p>But how are xG values such as this assigned to each shot? Where does the base number come from?</p>

          <p>There are several different xG models in existence, but their resulting numbers are all pretty similar. Here's Opta's explanation of how their xG is calculated:</p>

          <blockquote>
            <p className="mt-6">Over the years, Opta has collected numerous data points of in-game actions in all of the top football leagues. When creating the xG model, Sam Green and the Opta team analysed more than 300,000 shots and a number of different variables using Opta’s on-ball event data, such as angle of the shot, assist type, shot location, the in-game situation, the proximity of opposition defenders and distance from goal. They were then able to assign an xG value, usually as a percentage, to every goal attempt and determine how good a particular type of chance is. As new matches are played new data is collected to continuously refine the xG model.</p>
          </blockquote>

          <h3>Why is xG a useful stat?</h3>

          <p>Expected Goals is often derided by people who think stats in football have gone too far or have become too complex. But it's proven to be a solid way to evaluate the quality of chances a team is creating, and the performance of strikers in terms of whether they are scoring more or less than their "expected" total.</p>

          <p>So who has the best xG stats out of Lionel Messi and Cristiano Ronaldo?</p>

          <h2>Lionel Messi xG Stats</h2>
        
          <div className="w-full overflow-x-scroll scrolling-touch text-sm">
            <table>
              <thead>
                <tr>
                  <th>Messi (League)</th>
                  <th>xG</th>
                  <th>Actual Goals</th>
                  <th>xG vs Actual Goals</th>
                </tr>
              </thead>
              <tbody>
                {this.props.data.allSheetMessiClubStats.edges.filter(d => d.node.competition === "League" && d.node.xg && d.node.xg !== '0' && d.node.year !== '2023').map(d => d.node).map(
                  d => (
                    <tr key={d.year+d.xg}>
                      <td>{d.year}</td>
                      <td className="text-center">{d.xg}</td>
                      <td className="text-center">{d.goals}</td>
                      <td>
                        <span className={`${(d.goals-d.xg) < 0 ? 'text-red-500' : 'text-green-500'}`}>
                          {(d.goals-d.xg).toFixed(2)}
                        </span>
                        <small className="ml-2">({(d.goals-d.xg) < 0 ? 'less' : 'more'} than expected)</small>
                      </td>
                    </tr>
                  )
                )}
                </tbody>
            </table>
          </div>

          <h2>Cristiano Ronaldo xG Stats</h2>

          <div className="w-full overflow-x-scroll scrolling-touch text-sm">
            <table>
              <thead>
                <tr>
                  <th>Ronaldo (League)</th>
                  <th>xG</th>
                  <th>Actual Goals</th>
                  <th>xG vs Actual Goals</th>
                </tr>
              </thead>
              <tbody>
                {this.props.data.allSheetRonaldoClubStats.edges.filter(d => d.node.competition === "League" && d.node.xg && d.node.xg !== '0').map(d => d.node).map(
                  d => (
                    <tr key={d.year+d.xg}>
                      <td>{d.year}</td>
                      <td className="text-center">{d.xg}</td>
                      <td className="text-center">{d.year === "2022-2023" ? '1' : d.goals}</td>
                      <td>
                        <span className={`${(d.goals-d.xg) < 0 || d.year === "2022-2023" ? 'text-red-500' : 'text-green-500'}`}>
                          {d.year === "2022-2023" ? '1.08' : (d.goals-d.xg).toFixed(2)}
                        </span>
                        <small className="ml-2">({(d.goals-d.xg) < 0 || d.year === "2022-2023" ? 'less' : 'more'} than expected)</small>
                      </td>
                    </tr>
                  )
                )}
                </tbody>
            </table>
          </div>

          <h2>Analysis of Messi and Ronaldo xG Stats</h2>

          <p>As you can see, there is a lot of green in Leo Messi's xG table, so we can conclude that he consistently outperforms his Expected Goals value. In fact, he has scored a staggering {mxg} more goals than expected in La Liga since 2014/15.</p>

          <p>He also outperformed his xG by double figures in two separate seasons (+10.06 in 2016/17 and +10.03 in 2018/19).</p>

          <p>On the other hand, Cristiano Ronaldo's xG table has a lot more red in it, underperforming against his Expected Goals value in 4 league seasons since 2014/15. But Ronaldo is obviously better than your average striker, so what's going on here?</p>

          <h3>What to consider when using xG Stats</h3>

          <p>Ronaldo's xG stats highlight one of the flaws in using 'xG vs Actual Goals' as a performance metric for strikers. To explain: a player's "Expected Goals" total will go up with each shot they attempt. Ronaldo takes a <em>lot</em> of shots on goal compared to your average forward. When comparing the xG total with the actual goal total, we don't take into account the fact that your average striker wouldn't have been able to fashion those chances, or been in the right position to make those attempts on goal. Ronaldo's ability to make such a high number of goal attempts is what separates him from the average striker.</p>

          <p>So although analysing a player's expected vs actual goals is good for highlighting the best or most efficient finishers, it's also important to take note of players who simply can record high Expected Goal numbers on a consistent basis (like Ronaldo), because those players will naturally score more than others due to the amount of chances they are able to get on the end of or make for themselves.</p>

          <h2>How do Messi and Ronaldo's xG stats compare to the rest of Europe's elite?</h2>

          <p>Let's take a look at the xG stats from other elite forwards and see how they compare to Messi and Ronaldo's xG performance.</p>

          <p>The following xG stats cover league games only from <strong className="text-highlight">2014/15 - 2019/20</strong>.</p>

          <div className="w-full overflow-x-scroll scrolling-touch text-sm">
            <table>
              <thead>
                <tr>
                  <th>Player</th>
                  <th>xG</th>
                  <th>Actual Goals</th>
                  <th>xG vs Actual Goals</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Lionel Messi</td>
                  <td className="text-center">165.68</td>
                  <td className="text-center">201</td>
                  <td>
                    <span className="text-green-500">35.32</span>
                    <small className="ml-2">(more than expected)</small>
                  </td>
                </tr>
                <tr>
                  <td>Harry Kane</td>
                  <td className="text-center">115.98</td>
                  <td className="text-center">140</td>
                  <td>
                    <span className="text-green-500">24.02</span>
                    <small className="ml-2">(more than expected)</small>
                  </td>
                </tr>
                <tr>
                  <td>Antoine Griezmann</td>
                  <td className="text-center">79.74</td>
                  <td className="text-center">103</td>
                  <td>
                    <span className="text-green-500">23.26</span>
                    <small className="ml-2">(more than expected)</small>
                  </td>
                </tr>
                <tr>
                  <td>Son Heung-min</td>
                  <td className="text-center">46.78</td>
                  <td className="text-center">64</td>
                  <td>
                    <span className="text-green-500">17.22</span>
                    <small className="ml-2">(more than expected)</small>
                  </td>
                </tr>
                <tr>
                  <td>Mohamed Salah</td>
                  <td className="text-center">94.7</td>
                  <td className="text-center">108</td>
                  <td>
                    <span className="text-green-500">13.3</span>
                    <small className="ml-2">(more than expected)</small>
                  </td>
                </tr>
                <tr>
                  <td>Ciro Immobile</td>
                  <td className="text-center">100.46</td>
                  <td className="text-center">113</td>
                  <td>
                    <span className="text-green-500">12.54</span>
                    <small className="ml-2">(more than expected)</small>
                  </td>
                </tr>
                <tr>
                  <td>Kylian Mbappe</td>
                  <td className="text-center">69.47</td>
                  <td className="text-center">80</td>
                  <td>
                    <span className="text-green-500">10.53</span>
                    <small className="ml-2">(more than expected)</small>
                  </td>
                </tr>
                <tr>
                  <td>Z Ibrahimovic</td>
                  <td className="text-center">73.66</td>
                  <td className="text-center">84</td>
                  <td>
                    <span className="text-green-500">10.34</span>
                    <small className="ml-2">(more than expected)</small>
                  </td>
                </tr>
                <tr>
                  <td>Jamie Vardy</td>
                  <td className="text-center">93.23</td>
                  <td className="text-center">103</td>
                  <td>
                    <span className="text-green-500">9.77</span>
                    <small className="ml-2">(more than expected)</small>
                  </td>
                </tr>
                <tr>
                  <td>Luis Suarez</td>
                  <td className="text-center">137.83</td>
                  <td className="text-center">147</td>
                  <td>
                    <span className="text-green-500">9.17</span>
                    <small className="ml-2">(more than expected)</small>
                  </td>
                </tr>
                <tr>
                  <td>Cristiano Ronaldo</td>
                  <td className="text-center">180.07</td>
                  <td className="text-center">186</td>
                  <td>
                    <span className="text-green-500">5.93</span>
                    <small className="ml-2">(more than expected)</small>
                  </td>
                </tr>
                <tr>
                  <td>Sergio Aguero</td>
                  <td className="text-center">122.1</td>
                  <td className="text-center">128</td>
                  <td>
                    <span className="text-green-500">5.9</span>
                    <small className="ml-2">(more than expected)</small>
                  </td>
                </tr>
                <tr>
                  <td>Neymar</td>
                  <td className="text-center">108.11</td>
                  <td className="text-center">105</td>
                  <td>
                    <span className="text-red-500">3.11</span>
                    <small className="ml-2">(less than expected)</small>
                  </td>
                </tr>
                <tr>
                  <td>Karim Benzema</td>
                  <td className="text-center">100.55</td>
                  <td className="text-center">97</td>
                  <td>
                    <span className="text-red-500">3.55</span>
                    <small className="ml-2">(less than expected)</small>
                  </td>
                </tr>
                <tr>
                  <td>Edinson Cavani</td>
                  <td className="text-center">128.19</td>
                  <td className="text-center">122</td>
                  <td>
                    <span className="text-red-500">6.19</span>
                    <small className="ml-2">(less than expected)</small>
                  </td>
                </tr>
                <tr>
                  <td>R Lewandowski</td>
                  <td className="text-center">168.52</td>
                  <td className="text-center">162</td>
                  <td>
                    <span className="text-red-500">6.52</span>
                    <small className="ml-2">(less than expected)</small>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <p>Looking at Messi's xG stats in context against the rest of Europe's elite attacking players, you start to see how impressive they really are. He tops the charts in terms of goals, xG vs Actual Goals, and is 3rd behind Cristiano Ronaldo and Robert Lewandowski in terms of total Expected Goals.</p>

          <p>On that note, you may be surprised to see Ronaldo so low down the table on xG performance compared to his peers. However, as we've already touched on, this is somewhat misleading. Ronaldo tops the chart for total Expected Goals and is 2nd only to Lionel Messi when it comes to actual goals scored; obviously this counts for more in reality.</p>

          <p>It's a similar story with Robert Lewandowski - you may be surprised to see the 2020 FIFA Best Men's Player winner sits comfortably bottom of the table, scoring 6.52 goals <em>less</em> than he should have during this time period based on the quality of his chances. However again, he is 2nd for total Expected Goals, and 3rd in actual goals scored.</p>

          <p>Efficiency is less important when you have a world class ability to get on the end of chances or fashion them for yourself.</p>

          <p>When it comes to Lionel Messi, the xG stats suggest he is both ruthlessly efficient as well as having a knack for consistently generating a high number of goal attempts.</p>
          

        </CopyBlock>

        <LastUpdated type="Stats" />
        
      </Layout>

    )
  }
}

export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {competition: {eq: "League Since 14"}}) {
      edges {
        node {
          competition
          xgc
          apps
          minsPlayed
          goals
          xg
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {competition: {eq: "League Since 14"}}) {
      edges {
        node {
          competition
          xgc
          apps
          minsPlayed
          goals
          xg
        }
      }
    }
    allSheetMessiClubStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          goals
          xg
        }
      }
    }
    allSheetRonaldoClubStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          goals
          xg
        }
      }
    }
  }
`

export default Shots
